<script lang="ts">
  import { onMount, onDestroy, setContext } from 'svelte'
  import { writable } from 'svelte/store'

  import { ButtonSkeleton, AccordionSkeleton } from 'carbon-components-svelte'

  import { isDraft, shortId } from 'common/src/content'
  import { NullContent, type Content } from 'common/src/types'
  import Toolbar from '../content/toolbar/toolbar.svelte'
  import { state } from '../../store/state'

  import {
    type EditorShellContext,
    EDITOR_SHELL_CONTEXT,
    changeHistory,
    validationHandler,
    highlightedContent
  } from './editor-shell'
  import { type ValidationStatus } from './validation-handler'
  import RightPanel from './right-panel/right-panel.svelte'
  import { idAndTypeFromHash } from '../../routing/router-utils'
  import SwitchByType from '../atoms/switch-by-type.svelte'
  import MyIsMounted from '../atoms/my-is-mounted.svelte'
  import OutOfBoundHints from './out-of-bound-hints.svelte'
  import Zoom from './article/components/zoom.svelte'
  import { toolbarBusy } from '../content/toolbar/toolbar'

  let content: Content
  let isInEdit = false
  let contentEditor: HTMLElement | null = null
  let scrollUpdateSuspended = false
  let rightPanelWidth = 0
  let hasErrors = false
  let accordionWrapper: any
  let zoom = writable(1)
  let documentWidth = writable(900)
  let loadSkeleton = true

  let validationStatus: ValidationStatus = {
    counter: 0,
    detail: '',
  }

  let Editor: any = undefined

  const { validation, onChange, getWebPreviewUrl } = state.editorShell

  const subscriptions: (() => void)[] = []

  const handleScroll = () => {
    if (scrollUpdateSuspended) {
      return
    }

    const currentTab = state.tabs.selected.get()
    if (currentTab?.contentId !== content?.id) {
      return
    }

    state.editingState.update({
      scrollY: contentEditor?.scrollTop || 0,
    })
  }

  const setScrollListener = (hash: string) => {
    contentEditor?.removeEventListener('scroll', handleScroll)

    if (state.contentMap.some((cm) => hash.includes(`/${cm.type}/`))) {
      contentEditor?.addEventListener('scroll', handleScroll)
    }
  }

  const doTheMath = () => {
    if (contentEditor) {
      setScrollListener(state.currentHash.get())
    }
    if (accordionWrapper) {
      accordionWrapper.style.zoom = $zoom
    }
    if (content && highlightedContent.get().id !== content?.id) {
      highlightedContent.set(content)
    }
    hasErrors = validationStatus && validationStatus.counter > 0 && isInEdit

    if (content) {
      loadSkeleton = state.isLoading.get()
      validationStatus = {
        counter: 0,
        detail: '',
      }
      validationHandler.clear()

      if (!(content instanceof NullContent)) {
        Object.keys(content).forEach((key) =>
          validation(key, content[key], content, validationHandler, state)
        )
      }
    } else {
      loadSkeleton = true
    }
  }

  const restoreScroll = () => {
    const theTab = state.tabs.selected.get()
    const currentScroll = theTab?.editingState?.scrollY || 0

    setTimeout(() => {
      scrollUpdateSuspended = true
      contentEditor?.scrollTo(0, currentScroll)
      scrollUpdateSuspended = false
    }, 0)
  }

  subscriptions.push(
    state.currentHash.listen(async (hash) => {
      const { valid } = idAndTypeFromHash(hash, state)
      if (!valid) {
        return
      }

      setScrollListener(hash)
    })
  )

  subscriptions.push(
    state.currentContent.listen((value: Content) => {
      if (value instanceof NullContent || !value) {
        return
      }

      content = value

      const version = content?.version || ''

      if (!isDraft(version)) {
        updateTabs(content.type, shortId(version))
      }
      restoreScroll()
    })
  )

  subscriptions.push(
    state.isLoading.subscribe(() => {
      doTheMath()
    })
  )

  zoom.subscribe(() => {
    doTheMath()
  })

  const updateTabs = (contentType: string, id?: string) => {
    if (!id) {
      return
    }

    const storeAndType = state.storeAndType()

    const nameOnState = storeAndType.find((item) => item._type === contentType)?.nameOnState || ''

    const tabText = state[nameOnState].kind.getTitle(content)

    return state.tabs.ensureTab(id, tabText, contentType, nameOnState, contentType)
  }

  subscriptions.push(
    highlightedContent.subscribe(() => {
      doTheMath()
    })
  )

  onMount(() => {
    content = state.currentContent.get()
    return () => subscriptions.forEach((sub) => sub())
  })

  onDestroy(() => {
    contentEditor?.removeEventListener('scroll', handleScroll)
  })

  setContext<EditorShellContext>(EDITOR_SHELL_CONTEXT, {
    getWebPreviewUrl,
    changeHistory,
    highlightedContent,
  })

  $: {
    if (content || contentEditor || accordionWrapper || highlightedContent || isInEdit) {
      doTheMath()
    } else {
      doTheMath()
    }
  }
</script>

<div class="editor-shell flex flex-col h-full absolute w-full left-0 top-0" class:isInEdit>
  <div class="toolbar-wrapper bg-[var(--background)]" class:hasErrors>
    {#if loadSkeleton}
      <div class="flex flex-nowrap justify-between">
        <div class="flex justify-items-start gap-2">
          <ButtonSkeleton class="min-w-[10rem]" />
          <ButtonSkeleton class="min-w-[10rem]" />
          <ButtonSkeleton class="min-w-[10rem]" />
        </div>
        <div class="flex justify-items-end gap-2">
          <ButtonSkeleton class="w-[8rem]" />
          <ButtonSkeleton class="min-w-[11rem]" />
        </div>
      </div>
    {:else}
      <Toolbar bind:content {state} bind:isInEdit {validationStatus} {hasErrors} />
    {/if}
  </div>
  <div bind:this={contentEditor} class="flex flex-row overflow-y-auto max-h-full h-full items-stretch">
    <div
      class={`content-editor flex flex-col items-center transition-all duration-500`}
      style={`width: calc(100% - ${rightPanelWidth}px - var(--inner-content-padding))`}
    >
      <div
        class={`content-editor-inner flex flex-col w-[var(--editor-width)] max-w-[100%]`}
        style={`--editor-width: ${$documentWidth}px;`}
      >
        <div class="accordion-wrapper" bind:this={accordionWrapper}>
          {#if loadSkeleton}
            <AccordionSkeleton />
          {:else}
            <OutOfBoundHints {content} bind:isInEdit position="above" />
            <SwitchByType bind:content bind:Editor />
            <svelte:component
              this={Editor}
              bind:content
              isInEdit={isInEdit && !$toolbarBusy}
              {onChange}
              validationStatus={validationHandler.sourceErrors}
            />
            <OutOfBoundHints {content} bind:isInEdit position="below" />
          {/if}
        </div>
        <Zoom bind:zoom bind:documentWidth />
      </div>
    </div>
    {#if !loadSkeleton}
      <RightPanel bind:width={rightPanelWidth} bind:content />
    {/if}
  </div>
</div>
<MyIsMounted />

<style lang="postcss">
  .editor-shell {
    background-color: var(--selected);
  }
  .editor-shell.isInEdit {
    background-color: var(--secondary);
  }
  .toolbar-wrapper {
    border-block-end: 0.1rem solid var(--selected);
    padding: var(--inner-content-padding);
    transition: padding 0.5s;
  }
  .toolbar-wrapper.hasErrors {
    padding-block-end: unset;
  }
  .content-editor :global(.bx--accordion__title) {
    @apply text-h3;
  }
  .content-editor :global(.my-text-input .bx--text-input__field-outer-wrapper) {
    background: var(--cds-disabled-01);
  }
  .content-editor-inner :global(.bx--accordion__content) {
    padding: var(--inner-content-padding);
  }
  .content-editor-inner :global(li.bx--accordion__item) {
    background-color: var(--background);
    margin: var(--inner-content-padding);
  }
  .content-editor-inner :global(.bx--accordion__title) {
    @apply p-3 text-h3;
  }
  .content-editor-inner :global(div[data-invalid='true']),
  .content-editor-inner :global(.bx--accordion .bx--accordion__item[data-invalid='true']) {
    outline: 2px solid rgb(var(--red));
  }

  .content-editor-inner :global(input.data-invalid) {
    color: rgb(var(--red));
    font-weight: bold;
  }

  .content-editor-inner :global(.bx--accordion__heading) {
    border: 1px solid var(--background);
    border-block-start: 2px solid var(--background);
  }
</style>
